import ko from '../Library/Knockout';
import camelCaseObject from './camelCaseObject';

interface CreateComponentFromReactParams {
    tagName: string;
    importReactComponent: () => Promise<any>;
    passModuleData?: boolean;
}

export function createComponentFromReact({ tagName, importReactComponent, passModuleData = false }: CreateComponentFromReactParams) {
    ko.components.register(tagName, {
        lazyLoader: async () => {
            const { reactComponentBindingHandler } = (await import("@retsam/ko-react"));
            if (!ko.bindingHandlers.reactComponent)
                reactComponentBindingHandler.register();
            return {
                model: await createModel(),
                template: '<div data-bind="reactComponent: { Component: reactComponent, props: props }"></div>',
            };
        }
    });

    async function createModel() {
        const reactComponent = await importReactComponent();
        return function (props: any) {
            return {
                reactComponent,
                props: passModuleData ? camelCaseObject(props.moduleData) : props
            };
        };
    }
}
