export default function camelCaseObject(object: any): any {
    const newObject: any = {};
    if (!object) return;
    if (Array.isArray(object))
        return object.map(camelCaseObject);
    Object.keys(object).map(function (key: string) {
        try {
            if (typeof object[key] === "object" && object[key] !== null)
                newObject[camelCase(key)] = camelCaseObject(object[key]);
            else newObject[camelCase(key)] = object[key];
        } catch (err) { }
    });
    return newObject;

    function camelCase(str: string): string {
        return str.replace(/[^a-zA-Z0-9]([a-zA-Z0-9])/g, function () {
            return arguments[1].toUpperCase()
        });
    }
}
