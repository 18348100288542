import ko from '../../Library/Knockout';

export default ko.components.register('content-hub-posts', {
    lazyLoader: async () => {
        return {
            model: (await import('./ContentHubPostsModel')).default,
            template: (await import("./ContentHubPosts.html")).default,
        };
    },
    disableAwaitInView: true
});
