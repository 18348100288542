import ko from '../../Library/Knockout';

export default ko.components.register('pricing', {
    lazyLoader: async () => {
        return {
            model: (await import('./PricingDisplayModel')).default,
            template: (await import("./PricingTextTemplate.html")).default,
        };
    },
    disableAwaitInView: true
});
