import ko, { getAsObservable } from '../../Library/Knockout';

class StatusIcon {
    constructor(params, componentInfo) {
        this.params = params;
        this.componentInfo = componentInfo;
        this.isLoading = getAsObservable(this.params.isLoading);
        this.value = getAsObservable(this.params.value);
    }
}

const template = `
<div class="status-icon">
    <div class="status-positive" data-bind="if: !isLoading() && value()">
        <svg width="92" height="92" viewBox="0 0 92 92" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M27.625 50.2175L38.7619 61.3545L64.7481 35.3683" stroke="#94C83D"
                stroke-width="8" stroke-linecap="round" stroke-linejoin="round" />
            <circle cx="46" cy="46" r="42" stroke="#94C83D" stroke-width="8" />
        </svg>
    </div>
    <div class="status-negative" data-bind="if: !isLoading() && !value()">
        <svg width="92" height="92" viewBox="0 0 92 92" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M58.8612 32.8748L32.875 58.861" stroke="#EB5055" stroke-width="8"
                stroke-linecap="round" stroke-linejoin="round" />
            <path d="M32.8747 32.8748L58.8608 58.861" stroke="#EB5055" stroke-width="8"
                stroke-linecap="round" stroke-linejoin="round" />
            <circle cx="46" cy="46" r="42" stroke="#EB5055" stroke-width="8" />
        </svg>
    </div>
    <div class="status-loading" data-bind="if: isLoading()">
    <svg width="92" height="92" viewBox="0 0 92 92" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path fill="none" stroke="#E2E2E2" stroke-width="8" d="M46,4c10.08,0,19.34,3.55,26.58,9.48C81.99,21.18,88,32.89,88,46
            c0,23.2-18.8,42-42,42S4,69.2,4,46S22.8,4,46,4z" />
            <path fill="none" stroke="#AFAFAF" stroke-width="8" stroke-linecap="round"
                d="M46,88C22.8,88,4,69.2,4,46S22.8,4,46,4" />
        </svg>
    </div>
</div>
`;

export default ko.components.register('status-icon', {
    viewModel: {
        createViewModel: (params, componentInfo) => new StatusIcon(params, componentInfo)
    },
    template,
    synchronous: true
});
