import ko from '../../Library/Knockout';

export default ko.components.register('bng-description-form', {
    lazyLoader: async () => {
        return {
            model: (await import('./DescriptionFormModel')).default,
            template: (await import("./DescriptionFormTemplate.html")).default,
        };
    }
});
