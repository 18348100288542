import ko from '../../Library/Knockout';

export default ko.components.register('content-hub-gateway-form', {
    lazyLoader: async () => {
        import('bootstrap/js/dist/dropdown');
        return {
            model: (await import('./GatewayFormModel')).default,
            template: (await import("./GatewayForm.html")).default,
        };
    }
});
