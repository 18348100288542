import ko from '../../Library/Knockout';

export default ko.components.register('cro-testimonials', {
    lazyLoader: async () => {
        import('./CROTestimonials.less');
        return {
            model: (await import('./CROTestimonialsModel')).default,
            template: (await import("./CROTestimonialsTemplate.html")).default,
        };
    }
});
