import ko from '../../Library/Knockout';

export default ko.components.register('blog-subscription-form', {
    lazyLoader: async () => {
        return {
            model: (await import('./SubscriptionFormModel')).default,
            template: (await import("./SubscriptionForm.html")).default,
        };
    }
});
