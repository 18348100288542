import FooterSignup from "./FooterSignup";
import PageContext from "./PageContext";
import { shouldShowCookieSettings, showCookieSettingsBanner } from './../Helpers/TrackingConsentHelper';
import { awaitElementInView } from "../Functions/awaitElementInView";
import ko from "../Library/Knockout";

export default class Footer {
    constructor() {
        const context = new PageContext();
        this.el = document.querySelector('.footer-bottom-wrapper');
        this.signupForm = new FooterSignup();
        this.countryCode = context.currentCountry.countryCode;
        this.styleLoaded = ko.observable(false);
        this.loadStyle();
    }

    async loadStyle() {
        await import("../../style/Website/Common/Footer.less");
        this.styleLoaded(true);
        await awaitElementInView(this.el, {
            rootMargin: "500px"
        });
        import("../../style/Fonts/Roboto500.less");
        import("../../style/Fonts/Acumin800.less");
    }

    toggleCollapse(info, event) {
        event.target.parentNode.classList.toggle('active');
    }

    isCountry(...countryCodes) {
        return countryCodes.find(c => c == this.countryCode);
    }

    showCookieSettingsBanner() {
        return showCookieSettingsBanner();
    }

    get shouldShowCookieSettings() {
        return shouldShowCookieSettings();
    }

}

