import "core-js/stable";
import "regenerator-runtime/runtime";
import Footer from "./Footer";
import FooterSignup from "./FooterSignup";
import PageContext from './PageContext';
import BulletCarousel from './BulletCarousel';

// Register knockout components
import "./HeaderNavigation/HeaderNavigationComponent";
import "./PricingDisplay/PricingDisplayComponent";
import "./PricingDisplay/PricingTextComponent";
import "./TrialSignupForm/TrialSignupFormComponent";
import "./TrialSignupFormV2/TrialSignupFormComponent";
import "./RegionSwitcher/RegionSwitcherComponent";
import "./ContentHub/PostsReelComponent.js";
import "./ContentHub/PostsFilterComponent";
import "./ContentHub/GatewayFormComponent";
import "./ContentHub/FeaturedPostComponent";
import "./ContentHub/ContentHubPostsComponent";
import "./VideoEmbed/VideoEmbedComponent";
import "./SVGAnimations/AnimatedFeaturesComponent";
import "./SVGAnimations/AnimatedHowItWorksComponent";
import "./WebinarForm/WebinarFormComponent";
import "./ContactForm/ContactFormComponent";
import "./TabbedFeatureList/TabbedFeatureListComponent";
import "./EmailForm/EmailFormComponent";
import "./PictureCoverflow/PictureCoverflowComponent";
import "./FloatingCta/FloatingCtaComponent";
import "./ProductJourney/ProductJourneyComponent";
import "./CROFeatureList/CROFeatureListComponent";
import "./CROReviews/CROReviewsComponent";
import "./CROVideos/CROVideosComponent";
import "./CROTestimonials/CROTestimonialsComponent";

import "./Blog/PostsFilterComponent";
import "./Blog/PostsBoxComponent";
import "./Blog/SubscriptionFormComponent";

import "./Blog-Article/LeftMenuComponent";
import "./Blog-Article/RightMenuComponent";
import "./Blog-Article/PostBodyFooterComponent";

import "./BusinessNameGenerator/StatusIconComponent";
import "./BusinessNameGenerator/BusinessNameGeneratorComponent";
import "./BusinessNameGenerator/DescriptionFormComponent";

// React components
import "./CROTestimonialsReact/CROTestimonialsReactComponent";
import "./BlogPostsReel/BlogPostsReel.block.ko";
import "./ColFeatureList/ColFeatureList.block.ko";
import "./VideoEmbed/VideoEmbed.block.ko";
import "./NumberedList.block/NumberedList.block.ko";
import "./ProductFeatures/ProductFeatures.block.ko";
import "./ColumnCentred/ColumnCentred.block.ko";
import "./HighlightFeature/HighlightFeature.block.ko";
import "./GridSection/GridSection.block.ko";
import "./ThreeCardsFeatures/ThreeCardsFeatures.block.ko";
import "./FullIndex/FullIndex.block.ko";
import "./WebinarRedirection/WebinarRedirection.block.ko";

// Tracking
import { toLowerExceptAcronym } from './../Helpers/StringHelper';
import { loadPageStyles } from './../Functions/loadPageStyles';
import { getIsScrolledToTopObservable } from "../Helpers/ScrollHelper";

export default class App {
    constructor() {
        this.pageContext = new PageContext();
        this.footer = () => new Footer();
        this.signupForm = new FooterSignup();
        this.bullets = (config) => new BulletCarousel(config);

        loadPageStyles();

        (async () => {
            const { loadTracking } = (await import("../Tracking/loadTracking"));
            loadTracking();
        })();

        this.isScrolledToTop = getIsScrolledToTopObservable();
    }

    toLowerExceptAcronym(str) {
        return toLowerExceptAcronym(str);
    }

}
