export function toLowerExceptAcronym(str) {
    return str.replace(/(^|\s)[A-Z][a-z]/g, function (v) {
        return v.toLowerCase();
    });
}

export function getPhoneLink(numberFormatted) {
    const numberString = numberFormatted.toString();
    return `<a href="tel:${formatPhoneNumber(numberString)}" class="telephone-link">${numberString}</a>`;
}

export function formatPhoneNumber(number) {
    return number.replace(/[^\d]+/g, '');
}
