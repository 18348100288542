import ko from "../Library/Knockout";

const callbacks = [];
let isScrolledToTopObservable;
let isSafeAreaObservable;

window.addEventListener("scroll", scrollHandler, {
    passive: true
});

function scrollHandler() {
    callbacks.forEach(cb => cb());
}

export function getIsScrolledToTopObservable() {
    if (isScrolledToTopObservable) return isScrolledToTopObservable;
    isScrolledToTopObservable = ko.observable(true);
    callbacks.push(() => isScrolledToTopObservable(window.scrollY < 50));
    scrollHandler();
    return isScrolledToTopObservable;
}

export function getSafeAreaObservable() {
    if (isSafeAreaObservable) return isSafeAreaObservable;
    isSafeAreaObservable = ko.observable({});
    callbacks.push(() => {
        const style = window.getComputedStyle(document.documentElement)
        isSafeAreaObservable({
            top: style.getPropertyValue('--sat'),
            right: style.getPropertyValue('--sar'),
            bottom: style.getPropertyValue('--sab'),
            left: style.getPropertyValue('--sal'),
        });
    });
    return isSafeAreaObservable;
}

