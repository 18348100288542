import ko from '../../Library/Knockout';

export default ko.components.register('cro-reviews', {
    lazyLoader: async () => {
        import('./CROReviews.less');
        return {
            model: (await import('./CROReviewsModel')).default,
            template: (await import("./CROReviewsTemplate.html")).default,
        };
    }
});
