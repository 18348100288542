import ko from '../../Library/Knockout';

export default ko.components.register('posts-reel', {
    lazyLoader: async () => {
        return {
            model: (await import('./PostsReelModel')).default,
            template: (await import("./PostsReel.html")).default,
        };
    },
    disableAwaitInView: true
});
