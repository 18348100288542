import ko from '../../Library/Knockout';

export default ko.components.register('pricing-display', {
    lazyLoader: async () => {
        import("./PricingDisplay.less");
        return {
            model: (await import('./PricingDisplayModel')).default,
            template: (await import("./PricingDisplayTemplate.html")).default,
        };
    }
});
