import ko from '../../Library/Knockout';

export default ko.components.register('animated-how-it-works', {
    lazyLoader: async () => {
        import('./AnimatedHowItWorks.less');
        return {
            model: (await import('./AnimatedHowItWorksModel')).default,
            template: (await import("./AnimatedHowItWorksTemplate.html")).default,
        };
    }
});
