import ko from '../../Library/Knockout';

export default ko.components.register('blog-article-right-menu', {
    lazyLoader: async () => {
        return {
            model: (await import('./RightMenuModel')).default,
            template: (await import("./RightMenu.html")).default,
        };
    }
});
