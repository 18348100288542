import ko from "../Library/Knockout";
import Cookies from 'js-cookie';
import PageContext from "./PageContext";
import axios from "axios";
import GoogleTagManager from "../Tracking/GoogleTagManager";
import LocalStrings from "../Common/LocalStrings";
import { template } from "lodash-es";
import { isEmail } from "../Functions/isEmail";

export default class FooterSignup {
    constructor() {
        this.pageContext = new PageContext();
        this.isDisabled = ko.observable(false);
        this.hasFocus = ko.observable(false);
        this.email = ko.observable('');
        this.placeHolderText = LocalStrings.get("signup_email_placeholder");
        this.submitButtonText = LocalStrings.get("signup_email_go");

        this.formSubmissionAttempted = ko.observable(false);

        this.formValidation = [{
            message: LocalStrings.get("signup_email_validation_error"),
            isValid: () => this.email() != null && isEmail(this.email())
        }];

        this.errorList = ko.pureComputed(() => this.formValidation.filter(c => !c.isValid()));
    }

    get formId() {

        const formIds = {
            dev: '954f8235-d316-4815-90e5-7e1cfaf78ffa',
            qa: '9b503a8e-9b13-413e-b457-d2aecf54cfe7',
            prod: '040d7ef8-8f21-4314-9425-c32366d5d613'
        };

        if (this.pageContext.environment.isProduction())
            return formIds.prod;
        if (this.pageContext.environment.isQA())
            return formIds.qa;
        return formIds.dev;
    }

    submitHandler() {
        this.formSubmissionAttempted(true);
        if (this.errorList().length) return;

        const payload = {
            "fields": [{
                "name": "email",
                "value": this.email()
            }],
            "context": {
                "hutk": Cookies.get('hubspotutk'),
                "pageId": this.pageContext.contentId,
                "pageUri": location.href,
                "pageName": document.title
            }
        };

        axios({
            url: 'https://api.hsforms.com/submissions/v3/integration/submit/' + this.pageContext.portalId +
                '/' + this.formId,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(payload)
        })
            .then((res) => {

                const getSignupUrl = template(LocalStrings.get('signup_url_additional_info'));
                const redirectUrl = getSignupUrl({ email: encodeURIComponent(this.email()) });

                Promise.race([
                    new Promise((res) => setTimeout(res, 200)),
                    GoogleTagManager.queueEvent('Form Submission - Trial Signup Footer')
                ])
                    .then(() => window.location.href = redirectUrl);

            });
    }
}