import ko from '../../Library/Knockout';

export default ko.components.register('business-name-generator', {
    lazyLoader: async () => {
        import('./BusinessNameGenerator.less');
        return {
            model: (await import('./BusinessNameGeneratorModel')).default,
            template: (await import("./BusinessNameGeneratorTemplate.html")).default,
        };
    }
});
