import ko from '../../Library/Knockout';

export default ko.components.register('blog-posts-filter', {
    lazyLoader: async () => {
        import('bootstrap/js/dist/dropdown');
        import('bootstrap/js/dist/collapse');
        return {
            model: (await import('./PostsFilterModel')).default,
            template: (await import("./PostsFilter.html")).default,
        };
    },
    disableAwaitInView: true
});
