import ko from '../../Library/Knockout';

export default ko.components.register('contact-form', {
    lazyLoader: async () => {
        import("./ContactForm.less");
        return {
            model: (await import('./ContactFormModel')).default,
            template: (await import("./ContactFormTemplate.html")).default,
        };
    }
});
