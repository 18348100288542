import LocalStrings from "../../Common/LocalStrings";
import { formatPhoneNumber, getPhoneLink } from "../../Helpers/StringHelper";
import ko from "../../Library/Knockout";

import PageContext from "../PageContext";
import { getIsScrolledToTopObservable } from "../../Helpers/ScrollHelper";

export default class HeaderNavigation {
    constructor() {

        const exitNavBar = document.querySelector(".exit-navbar");
        exitNavBar.addEventListener("touchstart click", () => this.navBar.isOpen(false));

        this.context = new PageContext();
        this.mobileNavVersion = this.context.mobileNavigationVersion;
        this.homeUrl = this.getUrl("/");

        this.processedNavItems = this.navItems.map((ni) => {
            ni.active = ni.canonicalSlug == this.context.canonicalSlug;
            ni.localLabel = LocalStrings.get(`nav_item_label_${ni.id}`);
            ni.url = this.getUrl(ni.canonicalSlug);
            return ni;
        });

        this.isTop = getIsScrolledToTopObservable();
        this.isOpen = ko.observable(false);
        let modifiedNavbarPaths = ['/blog', '/content-hub', 'behind-the-tools'];
        this.isContentHub = modifiedNavbarPaths.filter(p => window.location.href.includes(p)).length > 0;
        
        const thisOpenSubscription = this.isOpen.subscribe(val => {
            if (!val) return;
            import('../../../style/Fonts/Roboto500.less');
            import('../../../style/Fonts/Acumin600.less');
            thisOpenSubscription.dispose();
        });
        
        this.toggleCollapse = function () {
            this.isOpen(!this.isOpen());
        };
        

        const navbarCollapse = document.getElementById('navbarCollapse');
        navbarCollapse.addEventListener('show.bs.collapse', this.isOpen(true));
        navbarCollapse.addEventListener('hide.bs.collapse', this.isOpen(false));
    }

    get salesNumber() {
        return LocalStrings.get("sales_number");
    }

    get supportNumber() {
        return LocalStrings.get("support_number");
    }

    get phoneNumber() {
        return this.isShowSupportNumber ? this.supportNumber : this.salesNumber;
    }

    get supportMessage() {
        const template = LocalStrings.get('header_mobile_nav_support_message');
        const phoneNumber = this.isShowSupportNumber ? getPhoneLink(this.supportNumber) : getPhoneLink(this.salesNumber);
        return template.replace('${supportNumber}', phoneNumber);
    }

    get isShowSupportNumber() {
        return this.context.hasLoggedIn && this.context.countryCode != 'za';
    }

    get isShowPhoneWidget() {
        return this.context.countryCode != 'global' && this.context.countryCode != 'fr';
    }
    
    get navItems() {
        if (!this.context.hideHeaderNav) {
            return [
                {
                    id: "how-it-works",
                    canonicalSlug: "/how-it-works",
                },
                {
                    id: "pricing",
                    canonicalSlug: "/pricing",
                },
                {
                    id: "contact-us",
                    canonicalSlug: "/contact-us",
                }
            ];
        } else {
            return [
                {
                    id: "learn-more",
                    canonicalSlug: "/",
                },
            ];
        }
    }

    getString(key) {
        return LocalStrings.get(key);
    }

    getUrl(slug) {
        const cleanSlug = slug.replace(/^\//, "");
        const pageMapItem = this.context.pageMap
            .find(pmi => pmi.canonicalSlug == cleanSlug && pmi.language == this.context.pageLanguageTag);

        return `/${pageMapItem?.slug || cleanSlug}`;
    }

    callNumberLink() {
        document.location.href = this.isShowSupportNumber ? `tel:${formatPhoneNumber(this.supportNumber)}` :`tel:${formatPhoneNumber(this.salesNumber)}`;
    }
}
