export function loadPageStyles() {
    const pageStyleLoaders = {
        "bng-page": async () => {
            import("../../style/Website/BusinessNameGeneratorPage.less");
        },
        "contact-us": async () => {
            import("../../style/Website/ContactUs.less");
        },
        "our-story": async () => {
            import("../../style/Website/OurStory.less");
        },
        "blog": async () => {
            import("../../style/Fonts/Roboto500.less");
            import("../../style/Blog/BlogListing.less");
            import("../../style/Blog/BlogArticle.less");
            import("../../style/Website/Modules/ContentHub.less");
        },
        "content-hub": async () => {
            import("../../style/Fonts/Roboto500.less");
            import("../../style/Blog/BlogListing.less");
            import("../../style/Blog/BlogArticle.less");
            import("../../style/Website/Modules/ContentHub.less");
        },
    };

    const pageStyles = window.pageStyles = window.pageStyles || [];
    pageStyles.map(ps => pageStyleLoaders[ps]());
}
