import ko from '../../Library/Knockout';

export default ko.components.register('cro-feature-list', {
    lazyLoader: async () => {
        
        return {
            model: (await import('./CROFeatureListModel')).default,
            template: (await import("./CROFeatureListTemplate.html")).default,
        };
    }
});
