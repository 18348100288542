import ko from '../../Library/Knockout';

export default ko.components.register('region-switcher', {
    lazyLoader: async () => {
        return {
            model: (await import('./RegionSwitcherModel')).default,
            template: (await import("./RegionSwitcherTemplate.html")).default,
        };
    },
    disableAwaitInView: true
});
