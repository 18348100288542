import ko from '../../Library/Knockout';

export default ko.components.register('webinar-form', {
    lazyLoader: async () => {
        import('./WebinarForm.less');
        return {
            model: (await import('./WebinarFormModel')).default,
            template: (await import("./WebinarFormTemplate.html")).default,
        };
    }
});
