import ko from '../../Library/Knockout';

export default ko.components.register('content-hub-featured-post', {
    lazyLoader: async () => {
        return {
            model: (await import('./FeaturedPostModel')).default,
            template: (await import("./FeaturedPost.html")).default,
        };
    },
    disableAwaitInView: true
});
