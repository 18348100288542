import ko from '../../Library/Knockout';

export default ko.components.register('blog-article-left-menu', {
    lazyLoader: async () => {
        return {
            model: (await import('./LeftMenuModel')).default,
            template: (await import("./LeftMenu.html")).default,
        };
    },
    disableAwaitInView: true
});
