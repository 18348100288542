import ko from '../../Library/Knockout';

export default ko.components.register('picture-coverflow', {
    lazyLoader: async () => {
        import("./PictureCoverflow.less");
        return {
            model: (await import('./PictureCoverflowModel')).default,
            template: (await import("./PictureCoverflowTemplate.html")).default,
        };
    },
    disableAwaitInView: true
});
