import ko from '../../Library/Knockout';

export default ko.components.register('tabbed-feature-list', {
    lazyLoader: async () => {
        import("./TabbedFeatureList.less");
        return {
            model: (await import('./TabbedFeatureListModel')).default,
            template: (await import("./TabbedFeatureListTemplate.html")).default,
        };
    }
});
