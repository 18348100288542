import ko from '../../Library/Knockout';

export default ko.components.register('blog-posts-box', {
    lazyLoader: async () => {
        return {
            model: (await import('./PostsBoxModel')).default,
            template: (await import("./PostsBox.html")).default,
        };
    },
    disableAwaitInView: true
});
