import ko from '../../Library/Knockout';

export default ko.components.register('email-form', {
    lazyLoader: async () => {
        return {
            model: (await import('./EmailFormModel')).default,
            template: (await import("./EmailFormTemplate.html")).default,
        };
    }
});
