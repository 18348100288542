import ko from '../../Library/Knockout';

export default ko.components.register('product-journey', {
    lazyLoader: async () => {
        import('./ProductJourney.less');
        import('../../../style/Fonts/Roboto700.less');
        return {
            model: (await import('./ProductJourneyModel')).default,
            template: (await import("./ProductJourneyTemplate.html")).default,
        };
    }
});
