import ko from '../../Library/Knockout';

export default ko.components.register('animated-features', {
    lazyLoader: async () => {
        import('./AnimatedFeatures.less');
        return {
            model: (await import('./AnimatedFeaturesModel')).default,
            template: (await import("./AnimatedFeaturesTemplate.html")).default,
        };
    }
});
