import { awaitElementInView } from '../Functions/awaitElementInView';
const ko = require('knockout');

ko.bindingHandlers.element = {
    init: function (element, valueAccessor) {
        var target = valueAccessor();
        target(element);
    }
};

// Override the default html binding to prevent errors from copy/pasting html with data-bind attributes
const originalHtmlBinding = ko.bindingHandlers.html;
const disableDataBindAttributes = str => str && str.replace(/data-bind=/g, 'data-bind-disabled=');
ko.bindingHandlers.html = {
    init: (element, valueAccessor, allBindings, viewModel, bindingContext) => {
        const newValueAccessor = () => disableDataBindAttributes(ko.unwrap(valueAccessor()));
        originalHtmlBinding.init(element, newValueAccessor, allBindings, viewModel, bindingContext);
    },
    update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        const newValueAccessor = () => disableDataBindAttributes(ko.unwrap(valueAccessor()));
        originalHtmlBinding.update(element, newValueAccessor, allBindings, viewModel, bindingContext);
    },
};

// Fix for ko.tasks.scheduler not running intermittently
setTimeout(() => ko.tasks.runEarly(), 1);

const lazyComponentLoader = {
    loadComponent: function (name, componentConfig, callback) {
        if (!componentConfig.lazyLoader) return callback(null);
        (async () => {
            const el = document.querySelector(name);
            if (!componentConfig.disableAwaitInView && el) await awaitElementInView(el);
            const { template, model } = await componentConfig.lazyLoader();
            callback({
                template: ko.utils.parseHtmlFragment(template),
                createViewModel: (params, componentInfo) => new model(params, componentInfo)
            });
        })();
    }
};

ko.components.loaders.unshift(lazyComponentLoader);

export function getAsObservable(value) {
    if (ko.isObservable(value))
        return value;
    return ko.observable(value);
}


ko.bindingHandlers.inView = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        function handleIntersect(entries, observer) {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    var callback = valueAccessor();
                    if (typeof callback === 'function') {
                        callback.call(bindingContext.$data);
                    }
                }
            });
        }

        let options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.0
        };

        let observer = new IntersectionObserver(handleIntersect, options);
        observer.observe(element);
    }
};


export default ko;