import ko from '../Library/Knockout';
import { assign } from 'lodash-es';
import PageContext from './PageContext';
import requestInterval from "request-interval";

export default class BulletCarousel {
    constructor(config) {
        const context = new PageContext();
        const defaultConfig = {
            autoScroll: true,
            autoScrollDelay: 8000,
            items: context.getValue(config.itemsPath, [])
        };

        config = assign(defaultConfig, config);

        this.autoScroll = ko.observable(config.autoScroll);
        this.contentContainerMaxHeight = ko.observable("100%");
        this.selectedIndex = ko.observable(0);
        this.itemContainerEl = ko.observable(null);

        this.items = config.items.map((r, i) => {
            var item = new TextItem(r);
            if (i == 0)
                item.selected(true);
            return item;
        });

        this.autoScrollIntervalId = requestInterval(config.autoScrollDelay, () => {
            if (!this.autoScroll())
                return requestInterval.clear(this.autoScrollIntervalId);
            var i = this.selectedIndex() + 1;
            var max = this.items.length;
            this.setSelected(i == max ? 0 : i)();
            this.resetHeight();
        }, config.autoScrollDelay);

        setTimeout(() => this.resetHeight());
    }

    clearSelected(i) {
        this.items.map((r) => {
            r.selected(false);
        });
    }

    setSelected(index) {
        return (item, e) => {
            this.selectedIndex(index);
            if (e) this.autoScroll(false);
            if (item && item.selected()) {
                return;
            }
            this.items.map((r, i) => {
                if (index == i) {
                    r.selected(true);
                    if (!e || true) {
                        r.resetAnimation(true);
                        setTimeout(() => {
                            r.resetAnimation(false);
                        }, 1);
                    }
                } else {
                    r.selected(false);
                }
            });
            this.resetHeight();
        };
    }

    calculateHeight() {
        var containerEl = this.itemContainerEl();
        if (!containerEl) return;
        var itemElements = containerEl.querySelectorAll('div');
        var out = 0;
        itemElements.forEach((el) => {
            if (out < el.offsetHeight) {
                out = el.offsetHeight;
            }
        });
        return out;
    }

    resetHeight() {
        this.contentContainerMaxHeight(this.calculateHeight());
    }
}

class TextItem {
    constructor(r) {
        Object.assign(this, r);
        this.bulletText = r.bulletText;
        this.bulletCaption = r.bulletCaption;
        this.bulletHeadline = r.bulletHeadline;
        this.image = r.image || {};
        this.selected = ko.observable(false);
        this.resetAnimation = ko.observable(false);
    }
}