export async function awaitElementInView(el, observerOptions = {}) {

    const defaultOptions = {
        root: null,
        rootMargin: "0px 0px 100px 0px",
        threshold: "0"
    };

    const IntersectionObserver = window.IntersectionObserver;
    if (!IntersectionObserver)
        return true;

    return new Promise((resolve) => {

        const options = {
            ...defaultOptions,
            ...observerOptions
        };

        const observer = new IntersectionObserver((entries, observer) => {
            const entry = entries.find(e => e.target == el);
            if (entry.isIntersecting) {
                observer.unobserve(el);
                observer.disconnect();
                resolve(true);
            }
        }, options);

        observer.observe(el);
    });
}
